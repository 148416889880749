import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [token, setToken] = useState(Cookies.get('token'));
  const [userId, setUserId] = useState('');
  const serverUri =  process.env.NODE_ENV === 'development' 
    ? process.env.REACT_APP_API_URL_LOCAL
    : process.env.REACT_APP_API_URL_REMOTE

  useEffect(() => {
    if(token) {
      const {exp}  = jwtDecode(token)
      const expirationTime = (exp * 1000) - 60000;
      setToken(token)
      Cookies.set('token', token);
      if (Date.now() >= expirationTime) {
        console.log('logout');
        logout()
      }
    }  
  }, [token]); 

  const logout = () => {
    Cookies.remove('token');
    setToken('')
  }

  const getUsersAccounts = async (setLoader) => {
    const {sub}  = jwtDecode(token)
    setUserId(sub)
    const response = await fetch(`${serverUri}/api/get-accounts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        userId
      })
    })
    setLoader(false)
    return response.json()
  }
  const getUsersWealth = async () => {
    const {sub}  = jwtDecode(token)
    setUserId(sub)
    const response = await fetch(`${serverUri}/api/get-users-wealth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        userId
      })
    })
    return response.json()
  }
  const createAnAccount = async (account, setLoader) => {
    const {sub}  = jwtDecode(token)
    setUserId(sub)
    account.UserId = userId;
    try{
      setLoader(true);
      const response = await fetch(`${serverUri}/api/create-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify(account)
      }) 
      const data = await response.json();
      toast(data.message);
    }catch(e){
      toast('Something went wrong!');
    } finally{
      setLoader(false)
    }
  }
  const updateAccount = async (account, setLoader) => {
    try{
      setLoader(true);
      const response = await fetch(`${serverUri}/api/update-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify(account)
      }) 
      const data = await response.json();
      toast(data.message);
    }catch(e){
      console.log(e);
      toast('Something went wrong!');
    } finally{
      setLoader(false)
    }
  }
  const deleteAccount = async (account, setLoader) => {
    try{
      setLoader(true);
      const response = await fetch(`${serverUri}/api/delete-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({Id: account.Id, UserId: account.UserId})
      }) 
      const data = await response.json();
      if (data.status !== "Ok") {
        throw data.message
      }
      toast(data.message);
    }catch(e){
      console.error(e);
      toast(e);
    } finally{
      setLoader(false)
    }
  }
  const createTransaction = async (transaction, setLoader) => {
    try{
      setLoader(true);
      const response = await fetch(`${serverUri}/api/create-transaction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify(transaction)
      }) 
      const data = await response.json();
      if (data.status !== "Ok") {
        throw data.message
      }
      toast(data.message);
    }catch(e){
      toast(e);
    } finally{
      setLoader(false);
    }
  }
  const deleteTransaction = async (id, setLoader) => {
    try{
      setLoader(true);
      const response = await fetch(`${serverUri}/api/delete-transaction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({id})
      }) 
      const data = await response.json();
      if(data.message !== "Ok"){
        throw data.message;
      }
      toast(data.message);
    } catch(e){
      toast(e);
    } finally{
      setLoader(false);
    }
  }
  const getTransactionTypes = async () => {
    const response = await fetch(`${serverUri}/api/get-transaction-types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
    })
    return response.json()
  }
  const getUsersTransactions = async () => {
    const {sub}  = jwtDecode(token)
    setUserId(sub) 
    try{
      const response = await fetch(`${serverUri}/api/get-users-transactions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({userId})
      }) 
      const data = await response.json();
      return data;
    }catch(e){
      toast('Something went wrong!');
    }
  }
  const signUp = async (email) => { 
    const response = await fetch(`${serverUri}/sign-up`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userName: email
      })
    })
    return response.json()
  }
  const getOTP = async (email) => { 
    const response = await fetch(`${serverUri}/get-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userName: email
      })
    })
    return response.json()
  }

  const validateOTP = async (otp, userId) => {
    const response = await fetch(`${serverUri}/validate-otp`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp,
        userId
      })
    })
    const responseJson = await response.json()

    if (responseJson.status === "Ok") {
      Cookies.set('token', responseJson.token)
    }

    return responseJson
  }

  const confirmEmail = async (userId) => {
    const response = await fetch(`${serverUri}/verify-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId
      })
    })
    const responseJson = await response.json();
    return responseJson
  }

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    setThemeSettings(prev => !prev)
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    setThemeSettings(prev => !prev)
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  return (
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings, 
        token,
        setToken,
        getOTP,
        validateOTP,
        logout,
        signUp,
        confirmEmail,
        getUsersAccounts,
        createAnAccount,
        updateAccount,
        deleteAccount,
        getUsersWealth,
        createTransaction,
        getTransactionTypes,
        getUsersTransactions,
        deleteTransaction
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
