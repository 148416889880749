import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const GetOTP = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [formValid, setFormValid] = useState(true)
  const { getOTP } = useStateContext();
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    setEmail(email)
    if (email.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }

  const requestOneTimePassword = async (email) => {
    setLoading(true)
    const response = await getOTP(email);
    if (response.status === "Ok") {
      navigate(`/validate-otp/${response.userId}`)
    }
    toast.error(response.message, {
      autoClose: 3000,
      theme: "dark",
    });
    setLoading(false)
  }

  return (
    <>
      <ToastContainer />
      <div className="bg-secondary-dark-bg rounded-lg md:w-1/2 w-5/6 m-auto px-5 md:px-10 pb-10 pt-5">
        <Header
          category="Login"
          title={loading ? 'Loading...' : 'Get OPT'} />
        <label className="block mb-2 text-md font-medium text-gray-400 dark:text-white">Email</label>
        <input
          onChange={(e) => validateEmail(e.target.value)}
          type="email"
          placeholder="m@m.m"
          className={`${!formValid
            ? 'border-green-500'
            : 'border-red-200'} bg-gray-700 border text-gray-400 text-sm rounded-lg block w-full p-2.5`} />
        <p className='mt-3 text-gray-400 text-sm'>Don't have an account? Create one <Link className="underline text-blue-600" to="/sign-up">here</Link>.</p>
        <button 
          disabled={formValid || loading} 
          onClick={() => requestOneTimePassword(email)}
          className={`${!formValid 
            ? 'bg-green-800' 
            : 'bg-red-400'} mt-5 text-gray-200 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 `}>Get OTP</button>
      </div>
    </>
  );
};

export default GetOTP;
