import React, { useState, useEffect } from 'react';
import { Layout } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { Link } from 'react-router-dom';
import { Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineElement } from 'chart.js';
import { CategoryScale } from 'chart.js';
import { Loader } from '../components';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

const Dashboard = () => {
  const [usersWealth, setUesrsWealth] = useState(0)
  const { getUsersWealth, getUsersAccounts, getUsersTransactions } = useStateContext();
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [accountsData, transactionsData, userWealth] = await Promise.all([
          getUsersAccounts(setLoader),
          getUsersTransactions(),
          getUsersWealth()

        ]);
        setAccounts(accountsData?.accounts || []);
        setTransactions(transactionsData?.transactions || []);
        setUesrsWealth(userWealth.wealth);
        setData({
          labels: accounts.map(item => item.AccountName),
          datasets: [
            {
              label: 'Account Wealth',
              data: accounts.map(item => item.CurrentWealth),
              backgroundColor: accounts.map(item => item.AccountColor),
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: .5,
              responsive: true,
              fill: false
            }
          ]
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
    fetchData()
  }, [usersWealth, loader])
  const orderedByDateTransactions = transactions?.sort((a, b) => {
    return new Date(a.Transactions.CreatedDate) - new Date(b.Transactions.CreatedDate);
  });

  return (
    <Layout>
      {loader && <Loader />}
      <div className='text-gray-200 bg-secondary-dark-bg h-22 rounded-xl w-3/4 mt-10 p-8 pt-9 m-3 mb-5'>
        <div className='flex justify-between items-center self-center'>
          {accounts.length === 0
            ? <Link className=" px-5 py-1 bg-blue-400 rounded-lg text-white" to="/accounts">Add an account</Link>
            : (
              <div>
                <p className="font-bold text-gray-400">Total</p>
                <p className="text-4xl">{usersWealth}</p>
              </div>
            )
          }
        </div>
      </div>
      <section className="md:w-1/4">
        <Doughnut data={data} />

        {accounts.map((account) => {
          return (
            <div className="my-4">
              <Line data={{
                labels: orderedByDateTransactions?.filter(transaction => transaction.Transactions.AccountId === account.Id).map((_, index) => index++),
                datasets: [{
                  label: account.AccountName,
                  data: orderedByDateTransactions?.filter(transaction => transaction.Transactions.AccountId === account.Id).map(transaction => transaction.Transactions.BalanceAfterTransaction),
                  fill: false,
                  borderColor: account.AccountColor,
                  tension: 0.1
                }]
              }} />
            </div>
          )
        })}
      </section>
    </Layout>
  );
}

export default Dashboard;

