import React, { useEffect, useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { Header } from '../components';
import { Loader } from '../components';
import { Layout } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { HiMiniBuildingOffice } from "react-icons/hi2";

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [accountName, setAccountName] = useState('');
  const [accountInitialBalance, setAccountInitialBalance] = useState();
  const [accountColor, setAccountColor] = useState(`#${(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')}`);
  const [accountDescription, setAccountDescription] = useState('');
  const [account, setAccount] = useState({});
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const { getUsersAccounts, createAnAccount, updateAccount, deleteAccount } = useStateContext();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsersAccounts(setLoader);
      setAccounts(data.accounts);
    }
    fetchData()
      .catch(console.error);
  }, [loader])

  const handleCreateAccount = (e) => {
    e.preventDefault()
    if (!accountName) {
      toast("Account name can not be empty!");
      return false;
    }
    createAnAccount({
      Id: uuidv4(),
      AccountName: accountName,
      AccountDescription: accountDescription,
      CurrentWealth: accountInitialBalance,
      AccountColor: accountColor
    }, setLoader);
    setAccountName('');
    setAccountDescription('');
    setAccountInitialBalance('');
  }

  const handleShowAccountDetails = (item) => {
    setShowAccountDetails(true);
    setAccount(item);
  }

  const handleUpdateAccountDetails = () => {
    updateAccount(account, setLoader);
    setShowAccountDetails(false);
  }
  const handleDeleteAccount = () => {
    const accName = prompt('Account and all related transactions will be deleted, please enter account name to proceed with deletion:')
    if (accName === account.AccountName) {
      deleteAccount(account, setLoader);
      setShowAccountDetails(false);
    }
  }
  return (
    <Layout>
      <ToastContainer />
      <div className="w-5/6 m-auto m-2 md:m-10 mt-10 p-5 md:p-10 bg-secondary-dark-bg rounded-3xl">
        <Header category="App" title="Accounts" />
        <div className='mt-5 w-full md:w-400'>
          {loader && <Loader />}
          <form className="max-w-md mx-auto mb-5">
            <div className="relative z-0 w-full mb-5 group">
              <h3 className="mb-4 text-gray-500 text-sm font-sm">Account name</h3>
              <input
                onChange={(e) => setAccountName(e.target.value)}
                value={accountName}
                type="text"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-2 border-gray-300 appearance-none rounded-md p-2" />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <h3 className="mb-4 text-gray-500 text-sm font-sm">Account description</h3>
              <input
                onChange={(e) => setAccountDescription(e.target.value)}
                value={accountDescription}
                type="text"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-2 border-gray-300 appearance-none rounded-md p-2" />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <h3 className="mb-4 text-gray-500 text-sm font-sm">Account balance</h3>
              <input
                onChange={(e) => setAccountInitialBalance(e.target.value)}
                value={accountInitialBalance}
                type="phone"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-2 border-gray-300 appearance-none rounded-md p-2" />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="hs-color-input" className="block text-sm font-medium mb-2 text-gray-500">Color</label>
              <input
                onChange={(e) => setAccountColor(e.target.value)}
                value={accountColor} type="color"
                className="p-1 h-10 w-14 block bg-gray-700 border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none" ></input>
            </div>
            <button
              onClick={handleCreateAccount}
              className="text-white bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Create</button>
          </form>
          {accounts.map((item, index) => (
            <div key={index} className='relative bg-gray-600 flex justify-between items-center mt-2 p-2 rounded-2xl hover:shadow-lg cursor-pointer'>
              <div className='flex gap-4'>
                <button
                  type='button'
                  style={{
                    color: "#fff",
                    backgroundColor: item.AccountColor
                  }}
                  className='text-2xl rounded-lg p-4 hover:drop-shadow-xl'
                >
                  <BsCurrencyDollar />
                </button>
                <div>
                  <p className='text-md text-gray-200 font-semibold'>
                    <HiMiniBuildingOffice className='inline' /> {item.AccountName}
                  </p>
                  <p className='text-sm text-gray-400'>
                    $ {item.CurrentWealth}
                  </p>
                </div>
              </div>
              <button onClick={() => handleShowAccountDetails(item)} className="absolute top-3 right-3">
                <svg width="24px" height="24px" viewBox="0 0 24 24" id="_24x24_On_Light_Edit" data-name="24x24/On Light/Edit" xmlns="http://www.w3.org/2000/svg">
                  <rect id="view-box" width="24" height="24" fill="none" />
                  <path id="Shape" d="M.75,17.5A.751.751,0,0,1,0,16.75V12.569a.755.755,0,0,1,.22-.53L11.461.8a2.72,2.72,0,0,1,3.848,0L16.7,2.191a2.72,2.72,0,0,1,0,3.848L5.462,17.28a.747.747,0,0,1-.531.22ZM1.5,12.879V16h3.12l7.91-7.91L9.41,4.97ZM13.591,7.03l2.051-2.051a1.223,1.223,0,0,0,0-1.727L14.249,1.858a1.222,1.222,0,0,0-1.727,0L10.47,3.91Z" transform="translate(3.25 3.25)" fill="#e5e5e5" />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </div>
      {showAccountDetails &&
        <section className="max-w-md mx-auto fixed bg-gray-700 p-8 shadow-lg z-10 shadow-xl rounded-md top-0">
          <button type="button" onClick={() => {
            setShowAccountDetails(!showAccountDetails)
          }} className="absolute top-3 right-3 padding-3">
            <svg width="24px" height="24px" viewBox="0 0 24 24" id="_24x24_On_Light_Cross" data-name="24x24/On Light/Cross" xmlns="http://www.w3.org/2000/svg">
              <rect id="view-box" width="24" height="24" fill="none" />
              <path id="Shape" d="M9.291,10.352l-4-4-4.005,4A.75.75,0,1,1,.22,9.291l4.005-4L.22,1.281A.75.75,0,0,1,1.281.22L5.286,4.225l4-4.005a.75.75,0,1,1,1.061,1.061l-4,4.005,4,4a.75.75,0,0,1-1.061,1.061Z" transform="translate(6.629 6.8)" fill="#e5e5e5" />
            </svg>
          </button>
          <div className="relative z-0 w-full mb-5 group">
            <input
              onChange={(e) => setAccount({ ...account, AccountName: e.target.value })}
              value={account.AccountName}
              type="text"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none" />
            <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Account name</label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              onChange={(e) => setAccount({ ...account, AccountDescription: e.target.value })}
              value={account.AccountDescription}
              type="text"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none" />
            <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Account description</label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <label htmlFor="hs-color-input" className="block text-gray-500 text-sm font-medium mb-2">Color</label>
            <input
              onChange={(e) => setAccount({ ...account, AccountColor: e.target.value })}
              value={account.AccountColor}
              type="color"
              className="p-1 h-10 w-14 block bg-gray-600 border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none" ></input>
          </div>
          <div className='flex justify-between'>
            <button
              onClick={handleUpdateAccountDetails}
              className="text-white bg-gray-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Update</button>
            <button
              onClick={handleDeleteAccount} className="text-white  rounded-lg text-sm w-full sm:w-auto p-1">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z" fill="gray" /></svg>
            </button>
          </div>
        </section>
      }
    </Layout>
  );
};

export default Accounts;
