import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';

const Loader = ({ bgColor, color, size, text, borderRadius, width, icon, bgHoverColor }) => {

    const { setIsClicked, initialState } = useStateContext();

    return (
        <div
            className="bg-half-transparent w-screen fixed nav-item top-0 right-0 bottom-0 left-0">
            <div className="loader">
                <div className="loader-bg">
                    <span>LOADING</span>
                </div>
                <div className="drops">
                    <div className="drop1"></div>
                    <div className="drop2"></div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <filter id="liquid">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="liquid" />
                    </filter>
                </defs>
            </svg>
        </div>
    );
}

export default Loader;

